import React from 'react'
import Social from '../Social'

// styles import
import * as styles from '../../styles'

const Home = () => (
  <styles.HomeSection>
    <styles.HiHero>Hi, there I'm Henry Arbolaez.</styles.HiHero>
    <styles.NameHero>Engineer. Thinker. Coffe Lover.</styles.NameHero>
    <styles.HeroDescription>
      <styles.Paragraph>
        I'm a coffee lover, thinker, and a software engineer at{' '}
        <a href="https://coursehero.com/">Course Hero</a>. I'm passionate about
        the idea of bringing people together to collaborate and building a great
        product.
      </styles.Paragraph>
    </styles.HeroDescription>

    <Social />
  </styles.HomeSection>
)

export default Home
